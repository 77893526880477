import React from "react"
import imgPortadaGuide from "@components/pageGuide/guides/guide2/image/imgPortadaGuia2.png"
import img1 from "@components/pageGuide/guides/guide2/image/img2.png"
import slider1 from "@components/pageGuide/guides/guide2/image/slider1.png"
import slider2 from "@components/pageGuide/guides/guide2/image/slider2.png"
import slider3 from "@components/pageGuide/guides/guide2/image/slider3.png"

const title = (
  <>
    <h1 className="titles-underlined-ebook ebook-ventas">
      ¿Cómo planificar el primer contacto con el cliente? 8 pasos para lograrlo
    </h1>
  </>
)

const textDescription = (
  <div>
    <p className="text-h2-ebook guide__description">
      Ofrecer una buena impresión será la clave para que tu lead ganado tenga
      mucha más probabilidad de que cierre una venta con tu negocio. Por eso, es
      importante optimizar este proceso combinando el talento de tu agente con
      estrategias y tácticas claves que te ayudarán a logralo.
    </p>
  </div>
)

const linkForm = (
  <span>
    Al registrarte, aceptas los términos y condiciones de nuestra{" "}
    <a
      style={{ color: "#2C5C82", textDecoration: "none" }}
      href="https://beexcc.com/politica-seguridad-privacidad.html"
    >
      Política de Privacidad.{" "}
    </a>
  </span>
)

const textOne = (
  <p>
    Hay mucho en juego en la reunión inicial con el cliente. Como personas,
    tomamos decisiones enormes e importantes basadas en las primeras
    impresiones. Solo tienes esta oportunidad de venderte a ti mismo y a tu
    empresa, y hay mucha presión para no estropear las cosas.
    <br /> <br />
    Afortunadamente, hemos reunido esta guía para ayudarte a triunfar en esa
    primera reunión con el cliente. Además, aprenderás algunos consejos
    sencillos para el éxito de la reunión con el cliente y los errores que debes
    evitar.
  </p>
)

const data = {
  start: {
    support: "Guía | Telemarketing",
    title: title,
    description: textDescription,
    classtext: "guide__description2",
    link: linkForm,
    href: "/ebook-8.pdf",
    brochure: "Guía-para-una-buena-experiencia-de-compra",
    img: imgPortadaGuide,
  },

  slider: {
    title: "¿Qué encontrarás?",
    underlined: "encontrarás?",
    checklist: [
      {
        key: 1,
        text: "Pasos para investigar a un cliente potencial. ",
      },
      {
        key: 2,
        text: "Qué habilidades blandas utilizar. ",
      },
      {
        key: 3,
        text: "Cómo crear ofertas de valor. ",
      },
      {
        key: 4,
        text: "Tips para tener éxito en cada reunión.  ",
      },
      {
        key: 5,
        text: "Errores que debes evitar el primer contacto. ",
      },
    ],
    listPictures: [
      {
        key: 1,
        img: slider1,
      },
      {
        key: 2,
        img: slider2,
      },
      {
        key: 3,
        img: slider3,
      },
    ],
  },

  description: {
    cards: [
      {
        text: textOne,
        photo: img1,
        class: true,
      },
    ],
  },
}

export default data
